<template>
  <div class="mb-lg-40">
    <content-placeholders v-if="$store.getters.getterIsLoadingBanner">
      <content-placeholders-img :class="classLoading" />
    </content-placeholders>
    <div v-else>
      <b-carousel id="carousel-1" class="carouselUI" :indicators="objBanner.pos_01.length > 1 ? true : false">
        <router-link
          v-for="(item, index) in objBanner.pos_01"
          :key="index"
          :to="item.target_url"
          :title="item.display_text"
          :target="item.target_type"
        >
          <b-carousel-slide>
            <template v-slot:img>
              <div
                v-lazy-container="{
                  selector: 'img',
                  error: 'https://d604u3cjqmj8m.cloudfront.net/resources/images/no-photo-banners.png'
                }"
                class="img-wrapper"
                :class="classImage"
              >
                <img class="img-small" :data-src="item.image_url" :alt="item.display_text" />
                <div v-if="isShowCaption" class="bannerCaption bannerCaptionGame">
                  <p>
                    <span class="text-uppercase">{{ item.display_text }}&nbsp;</span><span>{{ reward }}</span>
                  </p>
                </div>
              </div>
            </template>
          </b-carousel-slide>
        </router-link>
      </b-carousel>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    objBanner: {
      type: Object,
      default: () => {
        return {
          pos_01: [
            {
              id: 1,
              display_text: '',
              image_url: 'https://d604u3cjqmj8m.cloudfront.net/resources/images/no-photo-banners.png',
              target_url: '',
              target_type: '_self'
            }
          ]
        };
      }
    },
    classImage: {
      type: String,
      default: ''
    },
    classLoading: {
      type: String,
      default: ''
    },
    isShowCaption: {
      type: Boolean,
      default: false
    },
    reward: {
      type: String
    }
  },
  computed: {
    // objBanner() {
    //   let data = this.$store.getters.getterBanner;
    //   let obj = Object.keys(data).filter((p) => p === this.keyData)[0];
    //   let rs = !_.isEmpty(data[obj])
    //     ? data[obj]
    //     : {
    //         pos_01: [
    //           {
    //             id: 1,
    //             display_text: '',
    //             image_url: 'https://d604u3cjqmj8m.cloudfront.net/resources/images/no-photo-banners.png',
    //             target_url: '',
    //             target_type: '_self'
    //           }
    //         ]
    //       };
    //   return rs;
    // }
  }
};
</script>
<style lang="scss" scoped>
.img-small {
  border-radius: 3px;
}
</style>

<template>
  <div class="introContent">
    <IntroBanner
      v-if="isOnlyMobile"
      :objBanner="objBannerIntro"
      :classImage="'img-354-164 img-wrapper'"
      :classLoading="'placeholders-banner-product'"
    />
    <section class="mt-4 introContentBuyMe">
      <p class="color-blue-2 text-uppercase font-weight-600 mb-2">Mua hộ giá tốt</p>
      <div class="row m-0">
        <div class="col-6 col-sm-4 p-0" v-for="(item, index) in listEcommerce" :key="'hmV-' + index">
          <IntroBuyMeList :data="item"></IntroBuyMeList>
        </div>
      </div>
    </section>
    <section class="mt-4 introContentPromotion">
      <p class="color-blue-2 text-uppercase font-weight-600 mb-2">thông tin khuyến mãi</p>
      <div class="row row-introPromo">
        <div class="col-12 col-sm-6 mb-10 col-introPromo" v-for="(item, index) in listPromotion" :key="'hmV-' + index">
          <IntroPromotionList :data="item"></IntroPromotionList>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import IntroBanner from '@/components/Intro/Banner/IntroBanner';
import IntroBuyMeList from '../../components/Intro/BuyMe/List/IntroBuyMeList';
import IntroPromotionList from '../../components/Intro/Promotion/List/IntroPromotionList';
export default {
  components: {
    IntroBanner,
    IntroBuyMeList,
    IntroPromotionList
  },
  data() {
    return {
      listPromotion: [
        {
          image_url: 'https://d1aleksa3dshq6.cloudfront.net/muaho-concept/e0a78e62faa42619e3f75fedbf61cdc6.jpg',
          name: 'Shopee',
          target_url: 'https://shopee.vn/'
        },
        {
          image_url: 'https://d1aleksa3dshq6.cloudfront.net/muaho-concept/df16c8ea24a3dbb885a03e20ab0413a6.jpg',
          name: 'Shopee',
          target_url: 'https://shopee.vn/'
        },
        {
          image_url: 'https://d1aleksa3dshq6.cloudfront.net/muaho-concept/50ee16b7f30eb9a6d4888fd6c80d874c.jpg',
          name: 'Tiki',
          target_url: 'https://tiki.vn/'
        },
        {
          image_url: 'https://d1aleksa3dshq6.cloudfront.net/muaho-concept/f431d1c596efb182b51ae8e93aaddb24.jpg',
          name: 'Tiki',
          target_url: 'https://tiki.vn/'
        }
      ],
      listEcommerce: [
        {
          image_url: 'https://d16jabcdgww2ta.cloudfront.net/resources/vendors/lazada.png',
          name: 'Lazada',
          target_url: 'https://www.lazada.vn/'
        },
        {
          image_url: 'https://d16jabcdgww2ta.cloudfront.net/resources/vendors/lazada.png',
          name: 'Con cưng',
          target_url: 'https://concung.com/'
        },
        {
          image_url: 'https://d16jabcdgww2ta.cloudfront.net/resources/prd//Juno_1611923671195.jpg',
          name: 'Juno',
          target_url: 'https://juno.vn/'
        },
        {
          image_url:
            'https://d16jabcdgww2ta.cloudfront.net/resources/prd//kien-thuc-co-ban-online-ban-hang-sendo_1611924879928.jpg',
          name: 'Sendo',
          target_url: 'https://www.sendo.vn/'
        },
        {
          image_url:
            'https://d16jabcdgww2ta.cloudfront.net/resources/prd//ca5d12864c12916c05640b36e47ac5c9_1611924863172.png',
          name: 'Shopee',
          target_url: 'https://shopee.vn/'
        },
        {
          image_url: 'https://d16jabcdgww2ta.cloudfront.net/resources/prd//logo-1_1611924740038.png',
          name: 'Tuticare',
          target_url: 'https://www.tuticare.com'
        }
      ]
    };
  },
  computed: {
    objBannerIntro() {
      return {
        pos_01: [
          {
            image_url: 'https://d1aleksa3dshq6.cloudfront.net/muaho-concept/bannerIntro.jpg',
            target_url: '#',
            is_affiliate: 1,
            target_type: '_self'
          }
        ]
      };
    },
    isOnlyMobile() {
      return this.$store.getters.getterIsOnlyMobile;
    }
  }
};
</script>
<style lang="scss" scoped>
.introContent {
  padding: 10px;
  margin-bottom: 60px;
  min-height: calc(100vh - 110px);
}

.row-introPromo {
  margin-right: -5px;
  margin-left: -5px;
}
.col-introPromo {
  padding-left: 5px;
  padding-right: 5px;
}

@media (max-width: map-get($grid-breakpoints, 'sm')) {
  .introContentBuyMe {
    .col-6 {
      padding: 1rem;
      border: solid 1px #d3d3d3;
      border-bottom: none;
      background-color: #ffffff;
      &:nth-child(odd) {
        border-right: none;
      }
      &:nth-child(5) {
        border-bottom: solid 1px #d3d3d3;
      }
      &:nth-child(6) {
        border-bottom: solid 1px #d3d3d3;
      }
    }
  }
}

@media (min-width: map-get($grid-breakpoints, 'sm')) and (max-width: map-get($grid-breakpoints, 'fix-lg')) {
  .introContentBuyMe {
    .col-6 {
      padding: 1rem;
      border: solid 1px #d3d3d3;
      border-bottom: none;
      background-color: #ffffff;
      border-right: none;
      &:nth-child(3) {
        border-right: solid 1px #d3d3d3;
      }
      &:nth-child(4) {
        border-bottom: solid 1px #d3d3d3;
      }
      &:nth-child(5) {
        border-bottom: solid 1px #d3d3d3;
      }
      &:nth-child(6) {
        border-bottom: solid 1px #d3d3d3;
        border-right: solid 1px #d3d3d3;
      }
    }
  }
}
</style>

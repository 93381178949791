<template>
  <a @click="handleClick(data.target_url)" :title="data.name" class="pointer introBuyMeList d-block" target="_blank">
    <div class="img-wrapper img-121-36">
      <img v-lazy="data.image_url" :alt="data.name" class="img-small contentObFix" />
    </div>
  </a>
</template>
<script>
export default {
  props: {
    data: {
      type: Object,
      default: () => {
        return {
          image_url: 'https://d604u3cjqmj8m.cloudfront.net/resources/images/no-photo.png',
          name: '',
          target_url: ''
        };
      }
    }
  },
  methods: {
    handleClick(url) {
      if (window.ReactNativeWebView) {
        this.sendMessageToApp(JSON.stringify({ event: 'OPEN_DEEP_LINK', data: { url } }));
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.introBuyMeList {
  padding: 1rem 1.5rem;
}
</style>
